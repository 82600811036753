import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";

function Counter() {
  return (
    // 0BFFFF
    <div
      style={{
        backgroundColor: "#29AB87",
        marginTop: "3%",
        padding: "1% 0 1% 0",
      }}
    >
      <Box py="2%" display="flex" justifyContent="center">
        <Grid width="90%">
          <Grid container spacing={3} display="center" justifyContent="center">
            <Grid
              item
              minWidth="20%"
              width="121px"
              sx={{
                "@media screen and (max-width: 1250px)": { width: "33%" },
              }}
            >
              <Grid
                display="flex"
                alignItems="center"
                borderRadius="15px"
                boxShadow="2px 2px 10px 2px lightgrey"
                padding="2% 5% 2% 5%"
              >
                <Grid>
                  <img
                    src="./assets/workforce_counter.png"
                    width="70px"
                    height="70px"
                  />
                </Grid>

                <Stack
                  padding="5%"
                  display="flex"
                  textAlign="center"
                  width="100%"
                >
                  <Grid container justifyContent="center" alignItems="center">
                    <Typography variant="h4">50</Typography>
                    <Typography variant="h6">+</Typography>
                  </Grid>

                  <Typography>Workforce</Typography>
                </Stack>
              </Grid>
            </Grid>

            <Grid
              item
              minWidth="20%"
              width="121px"
              sx={{
                "@media screen and (max-width: 1250px)": { width: "34%" },
              }}
            >
              <Grid
                display="flex"
                alignItems="center"
                boxShadow="2px 2px 10px 2px lightgrey"
                borderRadius="15px"
                padding="2% 5% 2% 5%"
              >
                <Grid>
                  <img
                    src="./assets/app_solutions_counter.png"
                    width="70px"
                    height="70px"
                  />
                </Grid>

                <Stack
                  padding="5%"
                  display="flex"
                  textAlign="center"
                  width="100%"
                >
                  <Grid container justifyContent="center" alignItems="center">
                    <Typography variant="h4">100</Typography>
                    <Typography variant="h6">+</Typography>
                  </Grid>

                  <Typography>Apps & Solutions</Typography>
                </Stack>
              </Grid>
            </Grid>

            <Grid
              item
              minWidth="20%"
              sx={{
                "@media screen and (max-width: 1250px)": { width: "33%" },
              }}
            >
              <Grid
                display="flex"
                alignItems="center"
                boxShadow="2px 2px 10px 2px lightgrey"
                borderRadius="15px"
                padding="2% 5% 2% 5%"
              >
                <Grid>
                  <img
                    src="./assets/products_counter.png"
                    width="70px"
                    height="70px"
                  />
                </Grid>

                <Stack
                  padding="5%"
                  display="flex"
                  textAlign="center"
                  width="100%"
                >
                  <Grid container justifyContent="center" alignItems="center">
                    <Typography variant="h4">10</Typography>
                    <Typography variant="h6">+</Typography>
                  </Grid>

                  <Typography>Products</Typography>
                </Stack>
              </Grid>
            </Grid>

            <Grid
              item
              minWidth="20%"
              sx={{
                "@media screen and (max-width: 1250px)": { width: "33%" },
              }}
            >
              <Grid
                display="flex"
                alignItems="center"
                boxShadow="2px 2px 10px 2px lightgrey"
                borderRadius="15px"
                padding="2% 5% 2% 5%"
              >
                <Grid>
                  <img
                    src="./assets/clients_counter.png"
                    width="70px"
                    height="70px"
                  />
                </Grid>

                <Stack
                  padding="5%"
                  display="flex"
                  textAlign="center"
                  width="100%"
                >
                  <Grid container justifyContent="center" alignItems="center">
                    <Typography variant="h4">50</Typography>
                    <Typography variant="h6">+</Typography>
                  </Grid>

                  <Typography>Clients</Typography>
                </Stack>
              </Grid>
            </Grid>

            <Grid
              item
              minWidth="20%"
              sx={{
                "@media screen and (max-width: 1250px)": { width: "33%" },
              }}
            >
              <Grid
                display="flex"
                alignItems="center"
                boxShadow="2px 2px 10px 2px lightgrey"
                borderRadius="15px"
                padding="2% 5% 2% 5%"
              >
                <Grid>
                  <img
                    src="./assets/partnership_counter.png"
                    width="70px"
                    height="70px"
                  />
                </Grid>

                <Stack
                  padding="5%"
                  display="flex"
                  textAlign="center"
                  width="100%"
                >
                  <Grid container justifyContent="center" alignItems="center">
                    <Typography variant="h4">10</Typography>
                    <Typography variant="h6">+</Typography>
                  </Grid>

                  <Typography>Partners</Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Counter;
