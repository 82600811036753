import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";

function OurTeam() {
  const responsive = {
    "@media screen and (max-width: 1250px)": { width: "50%", my: "2%" },
  };

  return (
    <div>
      <div style={{ backgroundColor: "#f9f9f9", padding: "2%" }}>
        <Typography variant="h5" textAlign="center" fontSize={36} mb="2%">
          Our Team
        </Typography>

        <Box display="flex" justifyContent="center">
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "85%",
              ml: "2.5%",
            }}
          >
            <Grid
              item
              display="flex"
              textAlign="center"
              alignItems="center"
              width="25%"
              sx={responsive}
            >
              <Stack
                spacing={1}
                borderRadius="10px"
                boxShadow="1px 1px 5px 1px lightgrey"
                padding="15%"
                display="flex"
                justifyContent="center"
              >
                <img
                  src="./assets/profile.png"
                  width="200px"
                  height="200px"
                  alt="CEO"
                />
                <Typography fontWeight="bold" variant="h6">
                  Mukund Kumar
                </Typography>
                <Typography>Chief Executive Officer</Typography>
              </Stack>
            </Grid>

            <Grid
              item
              display="flex"
              textAlign="center"
              alignItems="center"
              width="25%"
              sx={responsive}
            >
              <Stack
                spacing={1}
                borderRadius="10px"
                boxShadow="1px 1px 5px 1px lightgrey"
                padding="15%"
                display="flex"
                alignItems="center"
              >
                <img
                  src="./assets/profile.png"
                  width="200px"
                  height="200px"
                  alt="COO"
                />
                <Typography fontWeight="bold" variant="h6" width="100%">
                  Rudra Vishwakarma
                </Typography>
                {/* <Typography fontWeight='bold' variant='h5' >Vishwakarma</Typography> */}
                <Typography>Chief Operating Officer</Typography>
              </Stack>
            </Grid>

            <Grid
              item
              display="flex"
              textAlign="center"
              alignItems="center"
              width="25%"
              sx={responsive}
            >
              <Stack
                spacing={1}
                borderRadius="10px"
                boxShadow="1px 1px 5px 1px lightgrey"
                padding="15%"
                display="flex"
                justifyContent="center"
              >
                <img
                  src="./assets/profile.png"
                  width="200px"
                  height="200px"
                  alt="CTO"
                />
                <Typography fontWeight="bold" variant="h6">
                  Dhruvil Sorathia
                </Typography>
                <Typography>Chief Technical Officer</Typography>
              </Stack>
            </Grid>

            <Grid
              item
              display="flex"
              textAlign="center"
              alignItems="center"
              width="25%"
              sx={responsive}
            >
              <Stack
                spacing={1}
                borderRadius="10px"
                boxShadow="1px 1px 5px 1px lightgrey"
                padding="15%"
                display="flex"
                justifyContent="center"
              >
                <img
                  src="./assets/profile.png"
                  width="200px"
                  height="200px"
                  alt="CMO"
                />
                <Typography fontWeight="bold" variant="h6">
                  Diwakar Pratap
                </Typography>
                <Typography>Chief Marketing Officer</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </div>

      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Box
          sx={{
            width: "90%",
            "@media screen and (max-width: 1250px)": { width: "80%" },
          }}
        >
          <Stack spacing={3} padding="4% 5% 4% 5%" borderRadius="20px">
            <Stack>
              <Grid container>
                <Stack spacing={2.5} display="flex">
                  <Typography variant="h4" width="100%" textAlign="left">
                    Note from CEO, LueurTech!!
                  </Typography>

                  <Typography lineHeight="2" textAlign="left">
                    Dear Reader,
                  </Typography>

                  <Typography lineHeight="2">
                    Selecting an IT services partner is a foundational decision
                    and one that is likely to cause ripples of impact throughout
                    your organization for years to come. That decision bears
                    tremendous weight in my mind, encouraging myself and the
                    Lueurtech software solutions team to bring our very best to
                    each engagement.
                  </Typography>

                  <Typography lineHeight="2">
                    We don’t start conversations with a pitch. Instead, we take
                    the time to listen to your needs, your goals, and your
                    business objectives — and yes, your technical challenges!
                    For the past 2 years, our team has been learning from and
                    growing with our customers by providing proactive and
                    trusted IT solutions. With that experience comes patience,
                    knowledge, and a solid toolkit that offers flexible
                    solutions to meet the needs of evolving businesses. A
                    partner is always looking to the future, creating
                    recommendations that will help you strategically position
                    for success over time. Our technical professionals want to
                    be a part of the conversations around technology that
                    permeate your business, communicating openly with your IT
                    and business leaders to align IT solutions to achieve
                    business goals and objectives.
                  </Typography>

                  <Typography lineHeight="2">
                    As we move forward into the future together, you need to
                    know that each client and staff member is an important
                    component in our success. We are continually looking for new
                    and creative ways to deliver on our promises — building on
                    our foundation of trust with each interaction.
                  </Typography>

                  <Typography lineHeight="2">
                    Thanks and regards, <br /> <b> Mukund Kumar </b> <br />{" "}
                    Founder & CEO
                    <br />
                    Lueurtech software solutions
                  </Typography>
                </Stack>
              </Grid>
            </Stack>
          </Stack>
        </Box>
      </div>
    </div>
  );
}

export default OurTeam;
