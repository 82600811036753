import React from "react";
import data from "../careers_list.json";
import { Button, Stack, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

function WebTrainer() {
  const location = useLocation();
  const career = location.state;
  const main = {
    margin: "0 15% 2% 15%",
  };

  const heading = {
    margin: "2% 0 0% 0",
  };

  const button = {
    backgroundColor: "lightblue",
    color: "black",
    borderRadius: "20px",
    border: "1px solid black",
  };

  return (
    <div style={main}>
      <Stack spacing={2} style={{ marginBottom: "1.5%" }}>
        <Typography variant="h4" fontWeight="bold" style={heading}>
          {career.title}
        </Typography>

        <Stack direction="row" spacing={4}>
          <Typography>Experience: {career.experience}</Typography>
          <Typography>Salary: {career.salary}</Typography>
        </Stack>

        <Stack direction="row" spacing={4}>
          <Typography>Job Type: {career.type}</Typography>
          <Typography>Location: {career.location}</Typography>
        </Stack>

        <Stack direction="row" spacing={4} alignItems="center">
          <Button variant="contained" style={button}>
            Apply Now
          </Button>
          <Typography>Last Date to Apply: {career.lastDate}</Typography>
        </Stack>

        <Typography variant="h5" fontWeight="bold" style={heading}>
          JOB DESCRIPTION
        </Typography>
        <Typography variant="h5" fontWeight="bold">
          {career.title}
        </Typography>
        <Typography fontWeight="bold" variant="h6">
          As a Web Development Trainer you would largely be involved in:{" "}
        </Typography>

        <ul>
          <li>
            <Typography>Providing training for the students.</Typography>
          </li>
          <li>
            <Typography>Schedule and execute training Programs.</Typography>
          </li>
          <li>
            <Typography>
              Conduct training programs for the new Joined interns.
            </Typography>
          </li>
          <li>
            <Typography>
              Design the material that can be used in providing the courses of
              training.
            </Typography>
          </li>
          <li>
            <Typography>
              These materials include the manuals, handouts, exercises and a lot
              more.
            </Typography>
          </li>
          <li>
            <Typography>
              Assess the students and interns after the training.
            </Typography>
          </li>
        </ul>

        <Typography variant="h6" fontWeight="bold">
          We would love you to have:
        </Typography>
        <ul>
          <li>
            <Typography>
              Hands-on and training experience on - HTML,CSS,Java Script,Web
              Architecture.
            </Typography>
          </li>
        </ul>

        <Typography variant="h5" fontWeight="bold" style={heading}>
          ELIGIBILITY CRITERIA
        </Typography>

        <ul>
          <li>
            <Typography>
              Graduate of B.E/B.Tech/M.E/M.Tech, MCA, and M.Sc. (CSE, IT only)
            </Typography>
          </li>
          <li>
            <Typography>
              Candidates should not have more than 12+ months of experience
            </Typography>
          </li>
          <li>
            <Typography>
              Would be available for both online and offline mode of teaching
            </Typography>
          </li>
          <li>
            <Typography>
              Candidates must be eligible to work in India either by
              citizenship/ have relevant work permit documents
            </Typography>
          </li>
        </ul>

        <Typography variant="h5" fontWeight="bold" style={heading}>
          PROCESS
        </Typography>
        <Typography variant="h6" fontWeight="bold">
          Assessment Process:
        </Typography>

        <Typography fontWeight="bold" variant="h6">
          Stage 1:
        </Typography>
        <ul>
          <li>
            <Typography>Resume Screening</Typography>
          </li>
        </ul>

        <Typography fontWeight="bold" variant="h6">
          Stage 2:
        </Typography>
        <ul>
          <li>
            <Typography>Interview</Typography>
          </li>
        </ul>
      </Stack>
      <Button variant="contained" style={button}>
        Apply Now
      </Button>
    </div>
  );
}

export default WebTrainer;
