import { Box, Typography } from "@mui/material";
import React from "react";
import ContactUsForm from "./ContactUsForm";

function ContactUsPage() {
  const heading = {
    color: "teal",
    backgroundImage: `url('./assets/bg.png')`,
    backgroundSize: "100%",
    height: "350px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "3%",
    // background: "linear-gradient(to right, white, teal)",
  };

  const content = {
    width: "60%",
    margin: "0 20% 0 20%",
  };

  return (
    <div >
      <div style={heading}>
        <Typography variant="h1" fontWeight="bold">
          CONTACT US
        </Typography>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: '60%', '@media screen and (max-width: 1250px)': { width: '90%' } }}>
          <ContactUsForm />
        </Box>
      </div>
    </div>
  );
}

export default ContactUsPage;
