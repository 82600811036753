import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";

function WhyChooseUs() {

  const responsive = {
    '@media screen and (max-width: 1250px)': {
      width: '100%',
      mt: '3%',
    }
  }

  return (

    <div>
      <Typography
        variant="h4"
        fontWeight="bold"
        textAlign="center"
        fontSize={36}
        mt="4%"
        mb="2%"
      >
        Why Choose Us?
      </Typography>

      <Box display="flex" justifyContent="center" mx="20%">
        <Grid>
          <Grid container mb="3%">
            <Grid item width='50%' display="flex" alignItems="center" sx={responsive} >
              <Grid
                display="flex"
                boxShadow="1px 1px 10px 1px lightgrey"
                padding="4%"
                mx="3%"
              >
                <Grid>
                  <img
                    src="./assets/innovation_icon.png"
                    height="100px"
                    width="100px"
                  />
                </Grid>
                <Stack mx={3} spacing={2}>
                  <Typography variant="h6">Innovation</Typography>
                  <Typography>
                    We innovate and impress through our rich knowledge and proven expertise.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>

            <Grid item width='50%' display="flex" alignItems="center" sx={responsive}>
              <Grid
                display="flex"
                boxShadow="1px 1px 10px 1px lightgrey"
                padding="4%"
                mx="3%"
              >
                <Grid>
                  <img
                    src="./assets/quality_icon.png"
                    height="100px"
                    width="100px"
                  />
                </Grid>
                <Stack mx={3} spacing={2}>
                  <Typography variant="h6">Quality</Typography>
                  <Typography>
                    We are committed to providing high-quality as well as robust services and products.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>

          <Grid container mb="3%">

            <Grid item width='50%' display="flex" alignItems="center" sx={responsive}>
              <Grid
                display="flex"
                boxShadow="1px 1px 10px 1px lightgrey"
                padding="4%"
                mx="3%"
              >
                <Grid>
                  <img
                    src="./assets/accountability_icon.png"
                    height="100px"
                    width="100px"
                  />
                </Grid>
                <Stack mx={3} spacing={2}>
                  <Typography variant="h6">Accountability</Typography>
                  <Typography>
                    Our team is the greatest strength of the firm, which honours the commitments we make, to our clients and each other.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>

            <Grid item width='50%' display="flex" alignItems="center" sx={responsive}>
              <Grid
                display="flex"
                boxShadow="1px 1px 10px 1px lightgrey"
                padding="4%"
                mx="3%"
              >
                <Grid>
                  <img
                    src="./assets/customer_icon.png"
                    height="100px"
                    width="100px"
                  />
                </Grid>
                <Stack mx={3} spacing={2}>
                  <Typography variant="h6">Customer Centricity</Typography>
                  <Typography>
                    We meet the mutually agreed-to requirements with clients and involve them at every step of work process.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>

          <Grid container mb="3%">
            <Grid item width='50%' display="flex" alignItems="center" sx={responsive}>
              <Grid
                display="flex"
                boxShadow="1px 1px 10px 1px lightgrey"
                padding="4%"
                mx="3%"
              >
                <Grid>
                  <img
                    src="./assets/performance_icon.png"
                    height="100px"
                    width="100px"
                  />
                </Grid>
                <Stack mx={3} spacing={2}>
                  <Typography variant="h6">
                    High Performance Solutions
                  </Typography>
                  <Typography>
                    The solutions we develop are not only user-friendly but are
                    also high performance solutions.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>

            <Grid item width='50%' display="flex" alignItems="center" sx={responsive}>
              <Grid
                display="flex"
                boxShadow="1px 1px 10px 1px lightgrey"
                padding="4%"
                mx="3%"
              >
                <Grid>
                  <img
                    src="./assets/track_icon.png"
                    height="100px"
                    width="100px"
                  />
                </Grid>
                <Stack mx={3} spacing={2}>
                  <Typography variant="h6">Proven Track Record</Typography>
                  <Typography>
                    “Let your work speak for yourself”. We work with lot of
                    dedication & passion and our work proves this.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default WhyChooseUs;
