import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function AboutUs() {
  const navigate = useNavigate();
  return (
    <div style={{ backgroundImage: `url(./assets/head1.jpg)`, padding: "3%" }}>
      <Typography
        variant="h4"
        fontWeight="bold"
        textAlign="center"
        fontSize={36}
        mt="0%"
        mb="2%"
        color="white"
      >
        About Us
      </Typography>

      <Box>
        <Stack display="flex" alignItems="center" spacing={2}>
          <Box
            width="60%"
            sx={{
              backgroundColor: "white",
              borderRadius: "10px",
              boxShadow: "1px 1px 10px 1px grey",
              "@media screen and (max-width: 1250px)": { width: "80%" },
            }}
          >
            <Typography textAlign="justify" padding="5%">
              LueurTech Software solutions is an outcome-focused, core IT
              company aimed at delivering unique, reliable, and innovative
              software solutions to small and large businesses using an agile
              approach of rapid software delivery. We harbor a team of sharp
              problem Solvers who “Think”, “Create”, and “Inspire” assisting our
              clients achieve “Digital Transformation”, by adhering to strict
              standards of quality, consistency and timeliness in the
              industry.We partner with you & are quick on our feet to understand
              your business objectives & offer rapid software development
              through agile mode of work. We realize we have an important role
              to play in making the world a better place in technology. At
              LueurTech Software solutions, we aspire to serve as catalysts to
              spark a positive change in society with the help of technology.
            </Typography>
          </Box>
          <Button
            variant="outlined"
            sx={{
              fontSize: "18px",
              color: "lightgray",
              border: "1px solid lightgrey",
              "&:hover": {
                borderColor: "lightyellow",
                color: "lightyellow",
              },
            }}
            onClick={() => navigate("/about-us")}
          >
            More About Us
          </Button>
        </Stack>
      </Box>
    </div>
  );
}

export default AboutUs;
