import "./App.css";
import Navbar from "./Pages/Header/Navbar";
import Footer from "./Pages/Footer/Footer";
import Body from "./Pages/Body/Body";
import CareersPage from "./Pages/Careers/CareersPage";
import InternWeb from "./Pages/Careers/Jobs/InternWeb";
import InternPython from "./Pages/Careers/Jobs/InternPython";
import PersonalAssistant from "./Pages/Careers/Jobs/PersonalAssistant";
import WebTrainer from "./Pages/Careers/Jobs/WebTrainer";
import JavaTrainer from "./Pages/Careers/Jobs/JavaTrainer";
import PythonTrainer from "./Pages/Careers/Jobs/PythonTrainer";
import PrivacyPoliciesPage from "./Pages/Privacy_Policies/PrivacyPoliciesPage";
import HomePage from "./Pages/Home/HomePage";
import ContactUsPage from "./Pages/Contact_Us/ContactUsPage";
import OurServicesPage from "./Pages/Our_Services/OurServicesPage";
import AboutUsPage from "./Pages/About_Us/AboutUsPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Body />}>
          <Route path="/" element={<HomePage />} />

          <Route path="/careers" element={<CareersPage />} />
          <Route path="/careers/intern-web" element={<InternWeb />} />
          <Route path="/careers/intern-python" element={<InternPython />} />
          <Route
            path="/careers/personal-assistant"
            element={<PersonalAssistant />}
          />
          <Route path="/careers/web-trainer" element={<WebTrainer />} />
          <Route path="/careers/java-trainer" element={<JavaTrainer />} />
          <Route path="/careers/python-trainer" element={<PythonTrainer />} />

          <Route path="/services" element={<OurServicesPage />} />

          <Route path="/contact-us" element={<ContactUsPage />} />

          <Route path="/about-us" element={<AboutUsPage />} />

          <Route
            path="/privacy-policies"
            element={<PrivacyPoliciesPage />}
          ></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
