import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import Development from "./Development";
import Internship from "./Internship";
import Training from "./Training";
import { useLocation } from "react-router-dom";

function OurServicesPage() {
  const ScrollToSection = () => {
    const { hash } = useLocation();

    useEffect(() => {
      if (hash) {
        const element = document.getElementById(hash.replace("#", ""));
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, [hash]);

    return null;
  };

  const heading = {
    color: "teal",
    backgroundImage: `url('./assets/bg.png')`,
    backgroundSize: "100%",
    height: "350px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "3%",
    // background: "linear-gradient(to right, white, teal)",
  };

  return (
    <div>
      <ScrollToSection />
      <div style={heading}>
        <Typography variant="h1" fontWeight="bold">
          OUR SERVICES
        </Typography>
      </div>
      <div id="development">
        <Development />
      </div>
      <div id="internship">
        <Internship />
      </div>
      <div id="training">
        <Training />
      </div>
    </div>
  );
}

export default OurServicesPage;
