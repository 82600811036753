import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";

function Development() {


    return (
        <div>
            <Typography
                variant="h3"
                textAlign="center"
                fontWeight='bold'
                my='2%'
            >
                Development
            </Typography>

            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }} >
                <Box sx={{ width: '70%', '@media screen and (max-width: 1250px)': { width: '90%' } }} >
                    <Typography textAlign='center' mb='1%'>
                        We design, develop, and deploy highly customizable commercial web & software solutions meeting your unique business requirements, optimizing your business processes.
                        We specialize in developing customized software adhering to narrowly defined requirements. Think of a tailor-made suit that fits perfectly. Our trends is to pace fast innovation and product development that is creating many new applications, services and platforms on which software is used.
                    </Typography>

                    <Typography textAlign='center' mb='1%'>
                        Our Application Development and Deployment services enable our clients to streamline operations, enhance partner and supplier relations, build social communities, and help improve customer loyalty by enabling self-service capabilities. We provide Flexibility to choose development framework & deployment runtime.

                        Our architectural expertise helps in reducing development cycle covering entire SDLC in Agile, Spiral and Waterfall Models with timely and quality delivery of solutions.
                    </Typography>

                    <Box>
                        <Grid container sx={{ display: 'flex', justifyContent: 'center' }} >

                            <Grid item display='flex' textAlign='center' width='50%'>
                                <Stack spacing={1} borderRadius='10px' boxShadow='1px 1px 5px 1px lightgrey' padding='5%' display='flex' alignItems='center' margin='4%' >
                                    <img src='./assets/web-development.png' width='100px' height='100px' />
                                    <Typography fontWeight='bold' variant='h6' >Web Development</Typography>
                                    <Typography >
                                        Every business that wishes to assert its dominance in the online sphere must have its own website.
                                        Lueurtech software solutions is one of the leading web development companies with a prominent presence. Being in the field for years we are quite profound in the nitty gritty of website designing thus evolving into one of the best web development agencies.
                                        We provide progressive web applications which is built on the latest technologies and have cross platform functionality as well.
                                    </Typography>
                                </Stack>
                            </Grid>

                            <Grid item display='flex' textAlign='center' width='50%' >
                                <Stack spacing={1} borderRadius='10px' boxShadow='1px 1px 5px 1px lightgrey' padding='5%' display='flex' alignItems='center' margin='4%' >
                                    <img src='./assets/mobile-app.png' width='100px' height='100px' />
                                    <Typography fontWeight='bold' variant='h6' >Mobile Application Development</Typography>
                                    <Typography >
                                        In today's world, mobile apps are evidently important for businesses, as a growing number of customers use their mobile devices to perform tasks remotely.
                                        We provide cross-platform app development solutions that includes building highly intriguing apps that can be deployed onto multiple platforms. We rely on building scalable services that satisfy the varied needs of our clients.
                                        Lueurtech employs the best practices and proven methodologies, and thorough testing for bug-free mobile applications.
                                    </Typography>
                                </Stack>
                            </Grid>

                            <Grid item display='flex' textAlign='center' width='50%' >
                                <Stack spacing={1} borderRadius='10px' boxShadow='1px 1px 5px 1px lightgrey' padding='5%' display='flex' alignItems='center' margin='4%' >
                                    <img src='./assets/desktop.png' width='100px' height='100px' />
                                    <Typography fontWeight='bold' variant='h6' >Desktop Software Development</Typography>
                                    <Typography >
                                        Our specialists are keeping pace with the most up-to-date technologies and have a proven track record in developing complex desktop applications for a variety of platforms. We examine your product’s objectives and suggest the best solution in terms of functionality, extensibility, and maintainability.                                    </Typography>
                                </Stack>
                            </Grid>

                            <Grid item display='flex' textAlign='center' width='50%'>
                                <Stack spacing={1} borderRadius='10px' boxShadow='1px 1px 5px 1px lightgrey' padding='5%' display='flex' alignItems='center' margin='4%' >
                                    <img src='./assets/ui-ux.png' width='100px' height='100px' />
                                    <Typography fontWeight='bold' variant='h6' >UI/UX Design</Typography>
                                    <Typography >
                                        We craft amazing and responsive user experience designs that meet our client's business goals and vision. We create innovative and most appealing user experiences that grabs user attention.

                                        Our expert designers create digital experiences that are clear, purposeful and visually enjoyable for the end-users.
                                    </Typography>
                                </Stack>
                            </Grid>

                            <Grid item display='flex' textAlign='center' width='50%' >
                                <Stack spacing={1} borderRadius='10px' boxShadow='1px 1px 5px 1px lightgrey' padding='5%' display='flex' alignItems='center' margin='4%' >
                                    <img src='./assets/devops.png' width='100px' height='100px' />
                                    <Typography fontWeight='bold' variant='h6' >DevOps</Typography>
                                    <Typography >
                                        DevOps is a set of follow-ups that systematizes the procedures among software improvement and IT teams, in demand to form, analyze, and release software quickly and consistently.
                                        Lueurtech's DevOps engineers help guide your transition to the cloud by architecting & implementing custom enterprise-grade cloud solutions that are flexible, fully scalable & highly available.
                                    </Typography>
                                </Stack>
                            </Grid>

                            <Grid item display='flex' textAlign='center' width='50%'>
                                <Stack spacing={1} borderRadius='10px' boxShadow='1px 1px 5px 1px lightgrey' padding='5%' display='flex' alignItems='center' margin='4%' >
                                    <img src='./assets/testing.png' width='100px' height='100px' />
                                    <Typography fontWeight='bold' variant='h6' >Software Testing & QA</Typography>
                                    <Typography >
                                        Empower your business for Next-Gen applications by implementing the best software testing methodologies and tools.
                                        Our innovative and forward-thinking approach eliminates errors, reduces overall cycle time and minimizes bugs. Keeping clients' customized requirements at the center, we offer a comprehensive set of manual and automated testing services adhering to standard quality assurance for best practices and processes.
                                    </Typography>
                                </Stack>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>
            </div>
        </div>
    )
}

export default Development