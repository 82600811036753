import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react'
import OurValues from './OurValues';
import OurTeam from './OurTeam';

function AboutUsPage() {

    const heading = {
        color: "teal",
        backgroundImage: `url('./assets/bg.png')`,
        backgroundSize: "100%",
        height: "350px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "3%",
        // background: "linear-gradient(to right, white, teal)",
    };

    return (
        <div>
            <div style={heading}>
                <Typography variant="h1" fontWeight="bold">
                    ABOUT US
                </Typography>
            </div>

            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }} >
                <Box sx={{ width: '70%', '@media screen and (max-width: 1250px)': { width: '80%' } }} >
                    <Stack spacing={3} padding='4% 5% 4% 5%' borderRadius='20px' >
                        <Stack>
                            <Grid container>
                                <Stack spacing={2} display='flex' alignItems='center'>
                                    <Typography variant='h4' width='100%' textAlign='center' fontWeight='bold' >Work Philosophy</Typography>
                                    <Typography lineHeight='2' textAlign='justify' >
                                        LueurTech Software solutions is an outcome-focused, core IT company aimed at delivering unique, reliable, and innovative software solutions to small and large businesses using an agile approach of rapid software delivery.
                                        LueurTech Software solutions is passionate about emerging technologies and are focused on product engineering, development, consulting, training and support services with highly skilled and experienced professionals. Our intense domain capability across verticals and businesses helps us get clearer understandings into customer needs.
                                    </Typography>

                                    <ul style={{ width: '80%', textAlign: 'justify' }} >
                                        <li>
                                            <Typography lineHeight='2' >
                                                We are here to create a global influence in the software development niche and set high standards of Innovation in the industry.
                                                We build a highly flexible team of certified industry experts who are easy to deploy, innovate and implement, helping clients materialize their ideas.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography lineHeight='2'>
                                                We partner with you & are quick on our feet to understand your business objectives & offer rapid software development through agile mode of work.
                                                We realize we have an important role to play in making the world a better place in technology.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography lineHeight='2'>
                                                At LueurTech Software solutions, we aspire to serve as catalysts to spark a positive change in society with the help of technology.
                                                Our approach is characterized by close collaboration with our client. In so doing, we ensure that our clients' needs are met as all relevant stakeholders are involved in the transformation. With passion, we constantly convey the agile mindset and its core principles. This approach has proven to shorten the time to market and increase employee ownership and engagement, resulting in a more dynamic and fun working environment for all stakeholders involved.
                                            </Typography>
                                        </li>

                                        <li>
                                            <Typography lineHeight='2'>
                                                We harbor a team of sharp problem Solvers who “Think”, “Create”, and “Inspire” assisting our clients achieve “Digital Transformation”, by adhering to strict standards of quality, consistency and timeliness in the industry.
                                                We are specialized in Custom software development, Product development and Software maintenance and have established our industry expertise in E-COMMERCE, EDUCATION, & TRAINING and INTERNSHIP.
                                            </Typography>
                                        </li>
                                    </ul>
                                </Stack>
                            </Grid>
                        </Stack>


                    </Stack>
                </Box>
            </div>

            <div style={{ backgroundImage: `url(./assets/mission_vision.jpg)` }} >
                <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                    <Grid container sx={{ width: '70%', justifyContent: 'center', alignItems: 'center', '@media screen and (max-width: 1250px)': { width: '100%' } }} >
                        <Grid item sx={{ width: '50%' }}>
                            <Box padding='10%' >
                                <Stack spacing={3} borderRadius='20px' display='flex' alignItems='center' sx={{ backgroundColor: 'white', padding: '5%', minHeight: '450px' }} >
                                    <img src='./assets/mission.png' width='80px' height='80px' />
                                    <Typography variant='h5' fontWeight='bold' style={{ borderBottom: '3px solid red' }}>Mission</Typography>
                                    <Typography textAlign='justify' lineHeight='2' >
                                        To be the first choice for our customers and business partners for delivering products and services of outstanding quality and at a competitive cost through working faster, simpler, and growing together.
                                        To be the employer of choice to attract, develop and retain the best talent available, enabling us to provide premier quality services to our customers.
                                        To lead the industry, delivering IT services with uncompromising integrity.
                                        To improve our client's organizational performance, increase revenues and create exceptional value.
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>

                        <Grid item sx={{ width: '50%' }}>
                            <Box padding='10%' justifyContent='center'>
                                <Stack spacing={3} borderRadius='20px' display='flex' alignItems='center' sx={{ backgroundColor: 'white', padding: '5%', minHeight: '450px' }} >
                                    <img src='./assets/vision.png' width='80px' height='80px' />
                                    <Typography variant='h5' fontWeight='bold' style={{ borderBottom: '3px solid red' }}>Vision</Typography>
                                    <Typography textAlign='justify' lineHeight='2' >
                                        To be the company that best understands and satisfies the products and services needs of our customers and business partners globally.
                                        To create a global influence in the software development niche and set high standards of Innovation in the industry.
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </div>

            <OurValues />
            <OurTeam />
            {/* <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>

            </div> */}
        </div>
    )
}

export default AboutUsPage