import React from 'react'
import { Box, Button, Grid, Stack, Typography } from "@mui/material";

function OurValues() {
    return (
        <div style={{ padding: '2%' }} >

            <Typography
                variant="h5"
                fontWeight="bold"
                textAlign="center"
                fontSize={36}
                mt="1%"
                mb="1%"
            >
                Our Values
            </Typography>

            <Box mx='15%'>
                <Grid textAlign='center'>
                    <Grid container>
                        <Grid item xs={6} borderRight='1px solid lightgrey' borderBottom='1px solid lightgrey' >
                            <Stack display='flex' alignItems='center' spacing={3} padding='10%' >
                                <Typography variant='h5' fontWeight='bold' >Integrity</Typography>
                                <img src='./assets/integrity.png' width='100px' height='100px' />
                                <Typography>Our first principal is honesty, which helps us meet the expectations we create.</Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={6} borderLeft='1px solid lightgrey' borderBottom='1px solid lightgrey' >
                            <Stack display='flex' alignItems='center' spacing={3} padding='10%' >
                                <Typography variant='h5' fontWeight='bold' >Commitment</Typography>
                                <img src='./assets/commitment.png' width='100px' height='100px' />
                                <Typography>Our commitment to our work and our clients is an unbreakable bond.</Typography>
                            </Stack>
                        </Grid>

                    </Grid>

                    <Grid container>
                        <Grid item xs={6} borderRight='1px solid lightgrey' borderTop='1px solid lightgrey' >
                            <Stack display='flex' alignItems='center' spacing={3} padding='10%' >
                                <Typography variant='h5' fontWeight='bold' >Passion</Typography>
                                <img src='./assets/passion.png' width='100px' height='100px' />
                                <Typography>Passion drives us to perform better, progress continually, and deliver timely.</Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={6} borderLeft='1px solid lightgrey' borderTop='1px solid lightgrey' >
                            <Stack display='flex' alignItems='center' spacing={3} padding='10%' >
                                <Typography variant='h5' fontWeight='bold' >Team-work</Typography>
                                <img src='./assets/teamwork.png' width='100px' height='100px' />
                                <Typography>Our team members are all about helping each other.</Typography>
                            </Stack>
                        </Grid>

                    </Grid>
                </Grid>
            </Box >
        </div>
    )
}

export default OurValues