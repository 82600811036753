import React from "react";
import HomeTop from "./HomeTop";
import OurServices from "./OurServices";
import HomeBottom from "./HomeBottom";
import StartCareer from "./StartCareer";
import WhyChooseUs from "./WhyChooseUs";
import AboutUs from "./AboutUs";
import Counter from "./Counter";

function HomePage() {
  return (
    <div>
      <HomeTop />
      <WhyChooseUs />

      <StartCareer />
      <OurServices />
      <AboutUs />
      <Counter />
      {/* <HomeBottom /> */}
    </div>
  );
}

export default HomePage;
