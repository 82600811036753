import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Career from "./Career";
import data from "./careers_list.json"

function CareersPage() {

    const heading = {
        color: 'teal',
        backgroundImage: `url('./assets/bg.png')`,
        backgroundSize: '100%',
        height: '350px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '3%'
    }

    const [careers, setCareers] = useState([])

    useEffect(() => {
        setCareers(data.careers_list)
    }, [])

    return (
        <div>
            <div style={heading} >
                <Typography variant="h1" fontWeight='bold' sx={{ '@media screen and (max-width: 1250px)': { fontSize: '75px' } }} >JOBS & OPPORTUNITIES</Typography>

            </div>
            {
                careers.map((item) =>
                    <Career career={item} />
                )
            }
        </div>
    )
}

export default CareersPage