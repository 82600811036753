import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { ImageListItem } from "@mui/material";
import navBarLinks from "./navbar_links.json";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const navigate = useNavigate();
  const navItems = navBarLinks.navbar_items;

  return (
    <Box sx={{ display: "flex", marginBottom: "64px" }}>
      {/* <CssBaseline /> */}
      <AppBar component="nav" sx={{ backgroundColor: "white", color: "teal" }}>
        <Toolbar>
          <ImageListItem>
            <img
              src="/assets/logo.png"
              style={{ height: "45px", width: "100%" }}
            />
          </ImageListItem>

          <Box
            style={{ position: "absolute", right: "0" }}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            {navItems.map((item) => (
              <Button
                key={item.name}
                sx={{
                  color: "teal   ",
                  fontWeight: "bold",
                  marginRight: "20px",
                }}
                onClick={() => navigate(item.link)}
              >
                {item.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Navbar;
