import { Grid, Stack, Typography } from "@mui/material";
import React from "react";

function HomeBottom() {

    return (
        <div>
            {/* <Stack mb={2}>
                <Stack direction='row'>
                    <Grid container>
                        <Grid item xs={6} spacing={2}>
                        <img src='/assets/web_dev2.png' width='100%' height='100%' />
                        </Grid>

                        <Grid item xs={6}>
                            <Stack px={15} my={30} spacing={2}>
                                <Typography variant='h5' fontWeight='bold'>About Web Development</Typography>
                                <Typography>We understand how important it is these days to have an online presence of your business and that’s where we help you out, by designing smart and functional website design which would help you attract potential clients in the industry. We believe that websites are the key boosters to the business these days and so our experts make sure to deliver the best creative design that would suit your business type and target potential customers.</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                    
                </Stack>

                <Stack direction='row'>
                <Grid container>
                        <Grid item xs={6}>
                            <Stack px={15} my={30} spacing={2}>
                                <Typography variant='h5' fontWeight='bold'>About Internship</Typography>
                                <Typography>Students participating in a company internship sharpen their professional skills taking a course, concurrently, that combines work and learning. The experience provides an opportunity to learn and practice career development strategies while developing the skills needed to succeed in the rapidly changing IT industry. Our internships meet experiential learning requirements along with hands on experience on real world projects.</Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={6} spacing={2}>
                            <img src='/assets/intern.jpg' width='100%' height='100%' />
                        </Grid>
                    </Grid>
                </Stack>
            </Stack> */}
        </div>
    )
}

export default HomeBottom